export const Checkout_EN_Trans = {
    checkout_summary: "Checkout Summary",
    company_name: "Company Name:",
    membership_choice: "Membership choice:",
    total_price: "Total Price:",
    months: "month",
    years: "year",
    paypal: "PayPal",
    credit_card: "Debit or Credit Card",
};
export const Checkout_DE_Trans = {
    checkout_summary: "Zusammenfassung der Bestellung",
    company_name: "Firmenname:",
    membership_choice: "Wahl der Mitgliedschaft:",
    total_price: "Gesamtpreis:",
    months: "monat",
    years: "jahr",
    paypal: "PayPal",
    credit_card: "Debit- oder Kreditkarte",
};
