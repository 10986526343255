import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PayPalIcon from "@mui/icons-material/AccountBalanceWallet";
import { useEffect, useState } from "react";
import { API_GET_USERID } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  boxShadow: 12,
  p: 4,
  borderRadius: "12px",
  outline: "none",
};

export default function CheckoutModal({
  setOpen,
  open,
  data,
  handlePaypalSubmit,
  handleCardSubmit,
  loading,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  let fiesta = localStorage.getItem("Fiesta");
  fiesta = fiesta ? JSON.parse(fiesta) : {};
  const [currentLoading, setCurrentLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const { t } = useTranslation();

  const getCompanyId = async (id) => {
    try {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res?.s) {
        const d = res?.r?.company_details?.company_name;
        d && setCompanyName(d);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fiesta && getCompanyId(fiesta?.id);
  }, [fiesta]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box>
              <Typography
                variant="h5"
                align="center"
                fontWeight="bold"
                color="text.primary"
                mb={2}
              >
                {t("checkout.checkout_summary")}
              </Typography>
              <Card
                sx={{
                  width: 350,
                  boxShadow: 3,
                  borderRadius: 2,
                }}
              >
                <CardContent>
                  <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography color="text.secondary">
                      {t("checkout.company_name")}
                    </Typography>
                    <Typography fontWeight="bold">{companyName}</Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography color="text.secondary">
                      {t("checkout.membership_choice")}
                    </Typography>
                    <Typography color="#fd5d29" fontWeight="bold">
                      €{data?.price} /{" "}
                      {data?.subType === 0
                        ? t("checkout.months")
                        : t("checkout.years")}
                    </Typography>
                  </Box>

                  <Divider sx={{ my: 2 }} />

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    bgcolor="#EDF4FF"
                    borderRadius={1}
                    p={1}
                  >
                    <Typography fontWeight="bold" color="text.secondary">
                      {t("checkout.total_price")}
                    </Typography>
                    <Typography fontWeight="bold" color="#164FA1" variant="h6">
                      €{data?.price}
                    </Typography>
                  </Box>

                  <Divider sx={{ my: 2 }} />

                  {/* PayPal Button */}
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      bgcolor: "#ffc439",
                      color: "#00457C",
                      fontWeight: "bold",
                      "&:hover": { bgcolor: "#f3b800" },
                      p: 1,
                      display: data?.subType === 1 ? "none" : "flex",
                    }}
                    disabled={loading}
                    startIcon={<PayPalIcon />}
                    onClick={() => {
                      handlePaypalSubmit(data);
                      setCurrentLoading(1);
                    }}
                  >
                    {loading && currentLoading == 1 ? (
                      <CircularProgress
                        style={{ color: "#f57c00" }}
                        size={26}
                      />
                    ) : (
                      t("checkout.paypal")
                    )}
                  </Button>

                  {/* Debit or Credit Card Button */}
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      bgcolor: "#333",
                      color: "#fff",
                      fontWeight: "bold",
                      mt: 1,
                      "&:hover": { bgcolor: "#000" },
                      p: 1,
                    }}
                    disabled={loading}
                    onClick={() => {
                      handleCardSubmit(data);
                      setCurrentLoading(2);
                    }}
                    startIcon={<CreditCardIcon />}
                  >
                    {loading && currentLoading == 2 ? (
                      <CircularProgress
                        style={{ color: "#f57c00" }}
                        size={26}
                      />
                    ) : (
                      t("checkout.credit_card")
                    )}
                  </Button>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
