import { Box, Typography, useTheme, Grid } from "@mui/material";
import React from "react";
import sideimg1 from "../../../Assets/images/strip1.webp";
import { useTranslation } from "react-i18next";
import CateringCard from "./Cards/CateringCard";
import Catering from "../../../Assets/images/home/2 Startseite Streetfoodfestivals.webp";
import Cat1 from "../../../Assets/images/home/3 Startseite Firmen Events.webp";
import Cat2 from "../../../Assets/images/home/4 Startseite Private Feiern.webp";
import ScrollAnimation from "react-animate-on-scroll";
const CateringSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const cateringData = [
    {
      id: 1,
      title: `${t("catSer.cateringData.card1")}`,
      desc: `${t("catSer.cateringData.desc1")}`,
      image: Catering,
    },
    {
      id: 2,
      title: `${t("catSer.cateringData.card2")}`,
      desc: `${t("catSer.cateringData.desc2")}`,
      image: Cat1,
    },
    {
      id: 3,
      title: `${t("catSer.cateringData.card3")}`,
      desc: `${t("catSer.cateringData.desc3")}`,
      image: Cat2,
    },
  ];

  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "100%",
        position: "relative",
        flexDirection: "column",
        paddingY: { xs: 4, md: 5 },
        gap: 5,
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{ width: "90%", marginX: "auto", maxWidth: "1400px", my: 1 }}
      >
        <Grid className="removeDefault" item xs={12}>
          <Grid
            container
            justifyContent="center"
            sx={{
              width: { xs: "100%", sm: "80%", md: "70%", lg: "50%", xl: "50%" },
              m: "auto",
            }}
          >
            <Grid className="removeDefault" item xs={12}>
              <Typography
                variant="h3"
                className="fontSnugle"
                sx={{
                  color: theme.palette.text.dark,
                  fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
                  textAlign: "center",
                }}
              >
                {/* {t("explore.title")} */}
                {t("catSer.title")}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: theme.palette.text.subHead,
                                    fontSize: { xs: "18px", md: "20px" },
                                    textAlign: "center",
                                    mt: "0.5rem",
                                }}
                            >
                                {t("catSer.subtitle")}
                            </Typography>
                        </Grid> */}
          </Grid>
        </Grid>

        <Grid
          item
          container
          className="removeDefault"
          xs={12}
          justifyContent="center"
          spacing={3}
          sx={{ alignItems: "center", flexWrap: "wrap" }}
        >
          {cateringData?.map((x, i) => {
            let animationType;
            if (i === 0) {
              animationType = "animate__fadeInUp";
            } else if (i === 1) {
              animationType = "animate__zoomIn";
            } else if (i === cateringData?.length - 1) {
              animationType = "animate__fadeInDown";
            }

            return (
              <Grid item xs={12} sm={9} md={4} key={i} spacing={2}>
                <ScrollAnimation animateIn={animationType}>
                  <CateringCard data={x} />
                </ScrollAnimation>
              </Grid>
            );
          })}
        </Grid>

        <Box sx={{ position: "absolute", right: 0, top: "50px" }}>
          <img src={sideimg1} alt="" height={40} />
        </Box>
      </Grid>
    </Box>
  );
};

export default CateringSection;
