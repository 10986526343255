import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/images/logo.webp";
import chatIcon from "../../Assets/images/chat.webp";
import ButtonComponent from "../Button/Button";
import TemporaryDrawer from "./MobileNavbar";
import { NavRoutes } from "./NavRoutes";
import LocalizationDropDown from "./LocalizationDropDown";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pathname = location.pathname;
  const [loginID, setLoginID] = useState(false);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const parseData = JSON.parse(localStorage.getItem("Fiesta"));
    const id = parseData?.id;
    const currentRole = parseData?.role;
    if (id) {
      setLoginID(id);
      setRole(Number(currentRole));
    }

    const handleAuthUpdate = (event) => {
      const { isLoggedIn } = event.detail;
      if (isLoggedIn) {
        const updatedData = JSON.parse(localStorage.getItem("Fiesta"));
        setLoginID(updatedData?.id);
      } else {
        setLoginID(false);
      }
    };
    window.addEventListener("authUpdate", handleAuthUpdate);
    return () => {
      window.removeEventListener("authUpdate", handleAuthUpdate);
    };
  }, [role, loginID]);

  useEffect(() => {
    const handleUserUpdate = (event) => {
      setRole(Number(event.detail.role));
    };

    window.addEventListener("userUpdate", handleUserUpdate);

    return () => {
      window.removeEventListener("userUpdate", handleUserUpdate);
    };
  }, []);

  const MenuItems = [
    { label: `${t("Navbar.Home")}`, path: NavRoutes.HOME },
    role !== 2 && {
      label: `${t("Navbar.BecomeAPartner")}`,
      path: NavRoutes.PARTNER,
    },
    role === 2 && {
      label: `${t("Navbar.mytrucks")}`,
      path: NavRoutes.MY_COMPANY,
    },
    { label: `${t("Navbar.FoodTrucks")}`, path: `${NavRoutes.TRUCKS}/0` },
    { label: `${t("Navbar.AboutUs")}`, path: NavRoutes.ABOUT },
    { label: `${t("Navbar.faq")}`, path: NavRoutes.FAQ },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "space-between", lg: "space-evenly" },
        px: 2,
        gap: 3,
        background: theme.palette.background.main,
        color: theme.palette.text.main,
        zIndex: 9,
      }}
    >
      {/* logo */}
      <Box>
        <img
          src={logo}
          alt="logo"
          height={70}
          width={110}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(NavRoutes.HOME)}
        />
      </Box>
      <Box className="flexCenter" sx={{ gap: 3 }}>
        <Box
          className="flexCenter"
          sx={{ gap: 3, display: { xs: "none", lg: "flex" } }}
        >
          {MenuItems.map(
            (x, i) =>
              x && (
                <Link
                  to={x?.path}
                  key={i}
                  className="link"
                  style={{
                    color:
                      pathname === x.path
                        ? theme.palette.text.active
                        : theme.palette.text.main,
                    textDecoration: "none",
                    fontSize: "18px",
                    fontWeight: pathname === x.path ? "600" : "500",
                  }}
                >
                  {x.label}
                </Link>
              )
          )}
        </Box>

        <Box
          className="flexCenter"
          sx={{ gap: 3, display: { xs: "none", lg: "flex" } }}
        >
          <Link to={NavRoutes.CHAT}>
            <img src={chatIcon} alt="" height={28} width={28} />
          </Link>
          <LocalizationDropDown />
          <Link to={NavRoutes.REACH}>
            <ButtonComponent
              label={`${t("Navbar.ReachUs")}`}
              sx={{ border: `1px solid ${theme.palette.background.btn}` }}
            />
          </Link>
          <Link
            style={{ textDecoration: "none" }}
            to={
              loginID
                ? role === 1
                  ? NavRoutes.PROFILE
                  : role === 2 && NavRoutes.OWNER_PROFILE
                : NavRoutes.LOGIN
            }
          >
            <ButtonComponent
              label={
                loginID ? `${t("Navbar.Profile")}` : `${t("Navbar.Login")}`
              }
              sx={{
                border: loginID
                  ? `1px solid ${theme.palette.text.active}`
                  : "none",
                display:
                  pathname === NavRoutes.LOGIN ||
                  pathname === NavRoutes.PROFILE ||
                  pathname === NavRoutes.OWNER_PROFILE
                    ? "none"
                    : "block",
                background: loginID
                  ? theme.palette.background.main
                  : theme.palette.background.dark,
                color: loginID
                  ? theme.palette.text.active
                  : theme.palette.text.defaultLight,
              }}
            />
          </Link>
        </Box>
        <Box
          className="flexCenter"
          sx={{ gap: 3, display: { xs: "flex", lg: "none" } }}
        >
          <TemporaryDrawer />
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
