import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { API_GET_USERID, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../Component/Navbar/NavRoutes";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import RenewSubsriptionModal from "../../Component/RenewSubscriptionModal/RenewSubscriptionModal";
import HomeSection from "./Sections/HomeSection";
import SearchSection from "./Sections/SearchSection";
import ExploreSection from "./Sections/ExploreSection";
import CateringSection from "./Sections/CateringSection";
import MenuSection from "./Sections/MenuSection";
import AboutSection from "./Sections/AboutSection";
import CateringServiceSection from "./Sections/CateringServicesSection";
import EventandcatSection from "./Sections/EventandcatSection";

const Subscription = React.lazy(() => import("./Sections/Subscription"));
const NewsletterBox = React.lazy(() => import("./Sections/Newsletter"));

const HomePage = () => {
  const userData = JSON.parse(localStorage.getItem("Fiesta"));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [NewsApiCalled, setNewsApiCalled] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [subType, setSubType] = useState("");

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const getDetails = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, userData?.id);
      if (res?.s)
      {
        const isActive = res?.r?.company_details?.subscription ? true : false;
        let isExpired =
          res?.r?.company_details?.subscription?.status === "EXPIRED";
        setIsSubscriptionExpired(isExpired);
        setSubType(
          res?.r?.company_details?.subscription?.plan_id ===
            "F-TRIAL31122024LAIRT"
            ? "Trial"
            : ""
        );
        setIsSubscribed(isActive);
      }
    } catch (error)
    {
      console.error(error);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const NewsLetterEmailSubscribe = urlParams.get("newsletter-subscribe");
  const NewsLetterEmailUnsubscribe = urlParams.get("newsletter-unsubscribe");
  const NewsLetterType = urlParams.get("type");

  const handleSubscribeNewsLetter = async (email, userType) => {
    try
    {
      const URL = NewsLetterEmailSubscribe
        ? API_endpoints.subscribeNewsLetter
        : API_endpoints.UnSubscribeNewsLetter;
      let res = await API_POST(URL, {
        email: email,
        type: userType ?? null,
      });

      if (res?.s)
      {
        setNewsApiCalled(true);
        toast.success(t("res.success"));
      } else
      {
        toast.info(res?.m);
      }
      navigate(NavRoutes.HOME);
    } catch (error)
    {
      console.error(error);
      navigate(NavRoutes.HOME);
    }
  };

  useEffect(() => {
    if (!NewsApiCalled)
    {
      if (NewsLetterEmailSubscribe && NewsLetterType)
      {
        handleSubscribeNewsLetter(NewsLetterEmailSubscribe, NewsLetterType);
      } else if (NewsLetterEmailUnsubscribe)
      {
        handleSubscribeNewsLetter(NewsLetterEmailUnsubscribe);
      }
    }
  }, [NewsApiCalled]);

  useEffect(() => {
    userData && getDetails();
  }, []);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <HomeSection />
      <SearchSection />
      <ExploreSection />
      {userData && userData.role === 2 && isSubscribed && <Subscription />}
      <CateringSection />
      <CateringServiceSection />
      <MenuSection />
      <AboutSection />
      <EventandcatSection />
      <NewsletterBox />
      <RenewSubsriptionModal
        open={isSubscriptionExpired}
        setOpen={setIsSubscriptionExpired}
        subType={subType}
      />

    </Box>
  );
};

export default HomePage;
