import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import localizIconGm from "../../Assets/images/localizationG.webp";
import localizIconEN from "../../Assets/images/usFlag.webp";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useTranslation } from "react-i18next";

export default function LocalizationDropDown() {
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") ?? "de"
  );
  const { i18n } = useTranslation();
  const languages = [
    {
      value: "en-US",
      label: "English",
      icon: localizIconEN,
    },
    {
      value: "de",
      label: "Deutsch",
      icon: localizIconGm,
    },
    // {
    //   value: "se",
    //   label: "Español",
    //   icon: localizIconSE,
    // },
  ];
  const handleChange = (event) => {
    if (event.target.value) {
      i18n.changeLanguage(event.target.value);
      localStorage.setItem("i18nextLng", event.target.value);
      setLanguage(event.target.value);
    }
  };

  return (
    <Box sx={{ minWidth: 60 }}>
      <FormControl fullWidth>
        <Select
          sx={{
            outline: "none",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiInputBase-input": {
              padding: "10px",
              display: "flex",
              alignItems: "center",
            },
            ".MuiSelect-icon": {
              right: "10px", // Adjust the position of the dropdown arrow
              top: "50%",
              transform: "translateY(-50%)", // Vertically center the dropdown arrow
            },
          }}
          renderValue={(e) => {
            const language = languages.find((x) => x.value === e);

            if (language) {
              return (
                <img
                  src={language.icon}
                  style={{ borderRadius: "50%" }}
                  alt={language.value}
                  height={"25px"}
                  width={"25px"}
                />
              );
            }
            return (
              <img
                src={localizIconEN}
                style={{ borderRadius: "50%" }}
                height={"25px"}
                alt={"En"}
                width={"25px"}
              />
            );
          }}
          IconComponent={KeyboardArrowDownRoundedIcon}
          value={language}
          onChange={handleChange}
        >
          {languages.map((x, i) => {
            return (
              <MenuItem key={i} value={x.value}>
                <img
                  src={x.icon}
                  alt=""
                  style={{ borderRadius: "50%" }}
                  height={"30px"}
                  width={"30px"}
                />
                <span style={{ color: "#000", paddingLeft: "0.5rem" }}>
                  {x.label}
                </span>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
