import { Checkout_EN_Trans } from "../Component/ChechoutModal/CheckOut_Trans";
import { footer_EN_trans } from "../Component/Footer/Footer_trans";
import { ConfirmationModal_EN_trans } from "../Component/Modal/ConfirmModal_trans";
import { Navbar_EN_Trans } from "../Component/Navbar/Nav_Trans";
import { response_EN_trans } from "../Component/responseMsg";
import { AboutUS_EN_trans } from "../Pages/AboutUs/About_trans";
import { Login_EN_Trans } from "../Pages/Auth/Login/Login_trans";
import { Register_EN_Trans } from "../Pages/Auth/Register/Register_trans";
import { BecomeAPartner_EN_Trans } from "../Pages/BecomeAPartner/BecomeAPartner_Trans";
// import { CateringList_EN_Trans } from "../Pages/CateringListScreen/CateringList_trans";
import { Faq_EN_trans } from "../Pages/FAQ/FAQ_trans";
import { FoodTruckScreen_EN_Trans } from "../Pages/FoodTruck/FoodTruck_Trans";
import { BookNow_EN_trans } from "../Pages/FoodTruckDetails/BookNow/BookNow_trans";
import { FoodTruckEdit_EN_Trans } from "../Pages/FoodTruckEdit/FoodTrucEdit_trans";
import { About_En_trans } from "../Pages/HomePage/HomeTrans/About_trans";
import { CatService_EN_Trans, } from "../Pages/HomePage/HomeTrans/CatService_trans";
import { Eventandcat_EN_trans } from "../Pages/HomePage/HomeTrans/EvenetAndcat_trans";
import { Explore_En_trans } from "../Pages/HomePage/HomeTrans/Explore_trans";
import { Home_EN_Trans } from "../Pages/HomePage/HomeTrans/Home_Trans";
import { Inquiry_EN_Trans } from "../Pages/HomePage/HomeTrans/InquiryForm_Trans";
import { Menu_En_trans } from "../Pages/HomePage/HomeTrans/Menu_trans";
import { Search_En_trans } from "../Pages/HomePage/HomeTrans/Search_Trans";
import { Subscription_EN_Trans } from "../Pages/HomePage/HomeTrans/Subscription_trans";
import { Imprint_EN_Trans } from "../Pages/Imprint/Imprint_trans";
import { myCompanyDetailsScreen_EN_Trans } from "../Pages/MyBusiness/MyCompanyDetailsScreen_trans";
import { Addtruck_EN_Trans } from "../Pages/MyFoodTruck/AddTruckScreen_trans";
import { Privacy_EN_Trans } from "../Pages/PrivacyPolicy/Privacy_trans";
import { ReachUs_EN_trans } from "../Pages/ReachUs/ReachUs_trans";
import { TandC_EN_Trans } from "../Pages/TermsAndConditions/TermsAndConditions_Trans";
import { OwnerProfile_EN_trans } from "../Pages/TruckOwnerProfile/OwnerProfil_trans";
import { MyBooking_EN_trans } from "../Pages/User/UserTrans/MyBooking_trans";
import { MyProfile_EN_trans } from "../Pages/User/UserTrans/MyProfile_trans";

export const English = {
  translation: {
    Navbar: Navbar_EN_Trans,
    section1: Home_EN_Trans,
    search: Search_En_trans,
    explore: Explore_En_trans,
    Register: Register_EN_Trans,
    Login: Login_EN_Trans,
    Menu: Menu_En_trans,
    subscription: Subscription_EN_Trans,
    About: About_En_trans,
    catSer: CatService_EN_Trans,
    eAndC: Eventandcat_EN_trans,
    becomePartner: BecomeAPartner_EN_Trans,
    myProfile: MyProfile_EN_trans,
    myBooking: MyBooking_EN_trans,
    footer: footer_EN_trans,
    bookNowForm: BookNow_EN_trans,
    contact: ReachUs_EN_trans,
    faq: Faq_EN_trans,
    about: AboutUS_EN_trans,
    ownerProfile: OwnerProfile_EN_trans,
    privacy: Privacy_EN_Trans,
    TC: TandC_EN_Trans,
    // cateringList: CateringList_EN_Trans,
    myCompDetails: myCompanyDetailsScreen_EN_Trans,
    addtruck: Addtruck_EN_Trans,
    foodTruck: FoodTruckScreen_EN_Trans,
    imprint: Imprint_EN_Trans,
    cModal: ConfirmationModal_EN_trans,
    inquiry: Inquiry_EN_Trans,
    editTruck: FoodTruckEdit_EN_Trans,
    res: response_EN_trans,
    checkout: Checkout_EN_Trans,
  },
};
