import { Box, Typography, useTheme, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import sideimg1 from "../../../Assets/images/strip1.webp";
import sideimg2 from "../../../Assets/images/strip2.webp";
import { useTranslation } from "react-i18next";
import TruckCard from "./Cards/TruckCard";
import ScrollAnimation from "react-animate-on-scroll";
import { API_GET, API_GET_OPEN } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import FoodTruckEditModal from "../../FoodTruckEdit/FoodTruckEditModal";
import { useCompanyId } from "../../../Context/CompanyIdContext";
import { Link } from "react-router-dom";
import { NavRoutes } from "../../../Component/Navbar/NavRoutes";

const ExploreSection = () => {
  const theme = useTheme();
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("Fiesta"));
  let { companyId, renderTrigger } = useCompanyId();
  companyId = Number(localStorage.getItem("Fiesta_compID"));
  const [truckData, setTruckData] = useState({});

  const openModal = async (data) => {
    setOpen(true);
    setTruckData(data);
  };

  const getData = async () => {
    try
    {
      let url = `${API_endpoints.getalltrucks}?count=0&offset=2`;
      let TruckRes = await API_GET_OPEN(url);
      let CompanyRes = await API_GET(
        `${API_endpoints.getallcaterers}?count=0&offset=1`
      );
      TruckRes?.s &&
        CompanyRes?.s &&
        setData([...CompanyRes?.r, ...TruckRes?.r]);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [renderTrigger]);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.section,
        position: "relative",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{ width: "90%", marginX: "auto", maxWidth: "1400px" }}
      >
        {/* Title Section */}
        <Grid item xs={12} className="removeDefault">
          <Grid
            container
            justifyContent="center"
            sx={{
              width: { xs: "100%", sm: "80%", md: "60%", lg: "50%", xl: "50%" },
              p: 1,
              m: "auto",
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h3"
                className="fontSnugle"
                sx={{
                  color: theme.palette.text.dark,
                  fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
                  textAlign: "center",
                }}
              >
                {t("explore.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.text.subHead,
                  fontSize: { xs: "18px", md: "20px" },
                  textAlign: "center",
                  mt: "0.5rem",
                }}
              >
                {t("explore.subtitle")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent={{ xs: "space-between", sm: "center" }}
          spacing={3}
          sx={{ zIndex: 1, p: 2, pb: 5 }}
        >
          {data?.length > 0 &&
            data?.map((x, i) => {
              let animationType;
              if (i === 0)
              {
                animationType = "animate__fadeInLeft";
              } else if (i === 1)
              {
                animationType = "animate__zoomIn";
              } else if (i === 2)
              {
                animationType = "animate__fadeInRight";
              }

              return (
                i < 3 && (
                  <Grid item xs={12} sm={12} md={4} key={"truck+" + i}>
                    <ScrollAnimation animateIn={animationType}>
                      <TruckCard
                        key={"truck+" + i}
                        data={x}
                        index={i}
                        setOpen={setOpen}
                        title={
                          x?.company_id === companyId && userData?.role === 2
                            ? t("explore.edit")
                            : t("explore.moreDetails")
                        }
                        openModal={openModal}
                      />
                    </ScrollAnimation>
                  </Grid>
                )
              );
            })}
        </Grid>

        {/* Side Images */}
        {/* <Grid item xs={12} sx={{ position: "relative" }}> */}
        <Box sx={{ position: "absolute", right: 0, top: "50px", zIndex: 0 }}>
          <img src={sideimg1} alt="" height={40} />
        </Box>
        <Box sx={{ position: "absolute", left: 0, bottom: "50px", zIndex: 0 }}>
          <img src={sideimg2} alt="" height={40} />
        </Box>
        {/* </Grid> */}

        {/* Button Section */}
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          sx={{ pb: 5, display: data?.length > 2 ? "flex" : "none" }}
        >
          <Link to={`${NavRoutes.TRUCKS}/1`}>
            {" "}
            <span
              className="veiwMore"
              style={{
                fontWeight: "600",
                color: theme.palette.text.active,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("explore.more")}
            </span>
          </Link>
        </Grid>
      </Grid>
      <FoodTruckEditModal
        setOpen={setOpen}
        open={open}
        data={truckData}
        getData={getData}
        compID={companyId}
      />
    </Box>
  );
};

export default ExploreSection;
