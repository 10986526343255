import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { TextField, useTheme } from "@mui/material";

const DatePickerComponent = ({ formik, name, disablePast, disableFuture }) => {
  const theme = useTheme();
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year", "month", "day"]}
          value={formik.values[name] ? dayjs(formik.values[name]) : null}
          name={`${name}`}
          sx={{
            width: "100%",
            borderRadius: "12px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
          }}
          format="DD/MM/YYYY"
          onChange={(newValue) => {
            formik.setFieldValue(
              `${name}`,
              newValue ? newValue.format("YYYY-MM-DD") : ""
            );
          }}
          disablePast={disablePast}
          disableFuture={disableFuture}
          renderInput={(params) => (
            <TextField
              {...params}
              name={`${name}`}
              fullWidth
              //   error={Boolean(formik.touched[name] && formik.errors[name])}
              //   helperText={formik.touched[name] && formik.errors[name]}
              InputProps={{
                sx: {
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default DatePickerComponent;
