import { Box } from "@mui/material";
import React from "react";
function AppLoader() {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                boxSizing: "border-box",
                background: "linear-gradient(180deg, #FFEDDD 0%, #F5F5F5 100%)",
            }}
        >
            <div class="loader"></div>
        </Box>
    );
}

export default AppLoader;

