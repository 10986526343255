export const API_endpoints = {
  baseURL: true
    ? "https://api.fiestacatering.de/" //production url (https://api.fiestacatering.de)
    : "https://devapi.fiestacatering.de/", // development url (https://devapi.fiestacatering.de)
  baseURL1: true
    ? "https://api.fiestacatering.de" //production url (https://api.fiestacatering.de)
    : "https://devapi.fiestacatering.de", // development url (https://devapi.fiestacatering.de)
  login: "/api/auth/login",
  register: "/api/auth/signup",
  forgot: "/api/auth/reset-password",
  countries: "/api/auth/countries",
  usergetbyid: "/api/v1/user/get-by-id",
  updateuser: "/api/v1/user/update",
  addcompany: "/api/v1/partner/company/add",
  updatecompany: "/api/v1/partner/company/update",
  addcaterer: "/api/v1/partner/company/caterer/add",
  updatecaterer: "/api/v1/partner/company/caterer/update",
  gettruckcat: "/api/v1/partner/company/food-truck/get-category",
  addtruck: "/api/v1/partner/company/food-truck/add-truck",
  addtruckimage: "/api/v1/partner/company/food-truck/add-truck-image",
  updateTruck: "/api/v1/partner/company/food-truck/update-truck",

  getmealcat: "/api/v1/partner/company/meal-diet/get-meal-category",
  getdietcat: "/api/v1/partner/company/meal-diet/get-diet-category",

  addMealData: "/api/v1/partner/company/meal-diet/add-update-company-meal",
  addDietData: "/api/v1/partner/company/meal-diet/add-update-company-diet",
  addIandC: "/api/v1/partner/company/image/add",
  deleteImage: "/api/v1/partner/company/image/update",

  addUpdateDietForFoodTruck:
    "/api/v1/partner/company/meal-diet/add-update-food-truck-diet",
  addUpdateMealForFoodTruck:
    "/api/v1/partner/company/meal-diet/add-update-food-truck-meal",

  getalltrucks: `/api/v1/partner/company/food-truck/get-all`,
  gettruckbyid: "/api/v1/partner/company/food-truck/get-details",
  getcompanybyid: "/api/v1/partner/company/get-details",
  edittruck: "/api/v1/partner/company/food-truck/update-truck",
  updatetruckimage: "/api/v1/partner/company/food-truck/update-truck-image",
  getTruckEquipmentRequirerment:
    "/api/v1/partner/company/requirement/get-requirement-category",
  AddUpdateTruckEquipmentRequirerment:
    "/api/v1/partner/company/requirement/add-update-food-truck-requirement",

  bookcatering: "/api/v1/booking/book",

  mybookings: "/api/v1/booking/get-all",
  cancelbooking: "/api/v1/booking/cancel",
  BookingAction: "/api/v1/booking",

  addrating: "/api/v1/rating-review/add-update",
  getrating: "/api/v1/rating-review/get-all",

  getfaq: "/api/v1/content/get-faq",
  GetPrivacy: "/api/v1/content/get-privacy-policy",
  GetTerms: "/api/v1/content/get-terms-condition",

  sendcontactus: "/api/v1/contact/send",

  getbookinganalytics: "/api/v1/booking/booking-analytics",

  getbookingsbyuser: "/api/v1/booking/get-all",
  getBookingDetailsById: "/api/v1/booking/get-details?booking_id=",

  getOwnerRating: "/api/v1/rating-review/get-all",
  RatingReviewAction: "/api/v1/rating-review/action",

  // getOwnerSubscriptionList: "/api/v1/subscription/plan-list",
  getOwnerSubscriptionList: "/api/v1/paypal/transaction",
  getallcaterers: "/api/v1/partner/company/caterer/get-all",

  checkAvailability: "/api/v1/booking/check-availability",
  lightUserDetails: "/api/v1/user/get-light-details?user_id=",

  addfoodtruck: "/api/v1/partner/company/food-truck/add-truck",
  getFooterContent: "/api/v1/content/get-footer-content",

  step1: "/api/v1/partner/company/get-details",
  step2: "/api/v1/partner/company/caterer/get-details",
  planSubscribe: "/api/v1/paypal/subscribe-plan",
  orderSubscriptionWithoutPaypal: "/api/v1/paypal/create-order",
  getFreeSubscriptionTillDecember: "/api/v1/paypal/subscribe-trial-plan",
  getSubscriptionPlanList: "/api/v1/paypal/plan-list",
  verifySubscription: "/api/v1/paypal/verify-subscription",
  CancelSubscription: "/api/v1/paypal/cancel-subscription",
  submitInquiryDetails: "/api/v1/inquiry/send",

  GetStatistics: "/api/v1/content/get-statistics",
  subscribeNewsLetter: "/api/v1/content/subscribe-newsletter",
  RequestSubscribeNewsLetter: "/api/v1/content/request-newsletter-subscribe",
  RequestUnSubscribeNewsLetter:
    "/api/v1/content/request-newsletter-unsubscribe",
  UnSubscribeNewsLetter: "/api/v1/content/unsubscribe-newsletter",
};
