// import React, { useEffect, useState } from "react";
// import { Navigate, useLocation } from "react-router-dom";

// const PrivateRoute = ({ children }) => {
//   const [loading, setLoading] = useState(true);
//   const [hasToken, setHasToken] = useState(false);

//   const location = useLocation();

//   useEffect(() => {
//     const storedData = localStorage.getItem("Fiesta");
//     if (storedData)
//     {
//       const { token } = JSON.parse(storedData);
//       if (token)
//       {
//         setHasToken(true);
//       }
//     }
//     setLoading(false);
//   }, []);

//   if (loading)
//   {
//     return null;
//   }

//   if (hasToken)
//   {
//     return children;
//   }

//   return <Navigate to="/login" state={{ from: location }} />;
// };

// export default PrivateRoute;

import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [hasToken, setHasToken] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const storedData = localStorage.getItem("Fiesta");
    if (storedData) {
      const { token } = JSON.parse(storedData);
      if (token) {
        setHasToken(true);
      }
    }
    setLoading(false);
  }, []);

  if (loading) {
    return null; // Render a loader or placeholder while checking
  }

  if (hasToken) {
    return children;
  }

  // Save the current path to local storage
  localStorage.setItem("redirectPath", location.pathname);

  // Redirect to the login page
  return <Navigate to="/login" />;
};

export default PrivateRoute;
