import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  useTheme,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  Select,
  FormGroup,
  Paper,
  Chip,
  Autocomplete,
} from "@mui/material";
import ButtonComponent from "../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import { StarSVG } from "../../Component/SVG";
import { API_GET, API_GET2, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import * as Yup from "yup";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DragandDrop from "../../Component/ImageUploader/DragandDrop";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "1400px",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  maxHeight: "calc(100vh - 100px)",
  overflow: "scroll",
};

export default function FoodTruckEditModal({
  setOpen,
  open,
  data,
  getData,
  compID,
  type,
}) {
  const handleClose = () => setOpen(false);
  const [image, setImage] = useState(null);
  // const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  // const [meal, setMeal] = useState([]);
  // const [diet, setDiet] = useState([]);
  const [equipment, setEquipment] = useState([]);

  const formik = useFormik({
    initialValues: {
      company_id: "",
      truck_name: "",
      address: "",
      truck_cat_id: 0,
      // specifications: "",
      requirement_ids: [],
      zip_code: "",
      // meal: [],
      // diet: [],
      // description: "",
      size: "",
      //.requirement_ids: "",
      operating_mode: "",
      is_water_required: "",
      work_area_radius: 0,
    },

    validationSchema: Yup.object({
      truck_name: Yup.string().required(t("Login.required")),

      address: Yup.string()
        .required(t("Login.required"))
        .max(200, t("Login.required") + ": Maximum 200 characters"),
      requirement_ids: Yup.array()
        .min(1, t("Login.required"))
        .required(t("Login.required")),
      // meal: Yup.array()
      //   .min(1, t("bookNowForm.required"))
      //   .required(t("bookNowForm.required")),
      // diet: Yup.array()
      //   .min(1, t("bookNowForm.required"))
      //   .required(t("bookNowForm.required")),
      zip_code: Yup.string().required(t("Login.required")),
      work_area_radius: Yup.number()
        .required(t("Login.required"))
        .test("not-zero", t("Login.required"), (value) => value !== 0),

      // description: Yup.string()
      //   .required(t("Login.required"))
      //   .max(500, t("Login.required") + ": Maximum 500 characters"),

      size: Yup.number()
        .required(t("Login.required"))
        .min(0, t("Login.required")),
      operating_mode: Yup.string().required(t("Login.required")),
      is_water_required: Yup.string().required(t("Login.required")),
    }),

    onSubmit: async (values) => {
      try {
        let res = await API_POST(API_endpoints.edittruck, values);
        if (image?.name) {
          const f = new FormData();
          f.append("image", image);
          f.append("id", data?.food_truck_image[0]?.id);

          let ans = await API_POST(API_endpoints.updatetruckimage, f);
        }
        // const mealData = new FormData();
        // mealData.append("truck_id", data?.id);
        // mealData.append("meal_ids", values.meal);

        // const dietData = new FormData();
        // dietData.append("truck_id", data?.id);
        // dietData.append("diet_ids", values.diet);

        const equipmentData = new FormData();
        equipmentData.append("truck_id", data?.id);
        equipmentData.append("requirement_ids", values.requirement_ids);

        // let mealResponse = await API_POST(
        //   API_endpoints?.addUpdateMealForFoodTruck,
        //   mealData
        // );

        // let dietRepsonse = await API_POST(
        //   API_endpoints?.addUpdateDietForFoodTruck,
        //   dietData
        // );

        let equipmentRepsonse = await API_POST(
          API_endpoints?.AddUpdateTruckEquipmentRequirerment,
          equipmentData
        );
        if (
          res.s &&
          // mealResponse?.s &&
          // dietRepsonse?.s &&
          equipmentRepsonse?.s
        ) {
          toast.success(t("res.success"));
          getData(0);
          setOpen(false);
          if (!type) {
            getTruck(data?.id);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const getTruck = async (id) => {
    setLoading(true);
    try {
      let res = await API_GET2(
        `${API_endpoints.gettruckbyid}?food_truck_id=${id}`
      );
      if (res?.s) {
        formik.setValues(res?.r);
        formik.setFieldValue(
          "requirement_ids",
          res?.r?.requirement?.map((x) => x?.status === 1 && x?.requirement_id)
        );
        formik.setFieldValue("image", res?.r?.food_truck_image[0]?.image);
      }
      setImage(res?.r?.food_truck_image[0]?.image);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const handleCheckboxChange = (type) => (value) => (event) => {
  //   const { checked } = event.target;
  //   const updatedArray = checked
  //     ? [...formik?.values[type], value]
  //     : formik.values[type].filter((item) => item !== value);
  //   formik.setFieldValue(type, updatedArray);
  // };

  // const renderCheckbox = (labelKey, value, type, key) => (
  //   <FormControlLabel
  //     key={key}
  //     control={
  //       <Checkbox
  //         id={labelKey}
  //         checked={formik.values[type]?.includes(value)}
  //         onChange={handleCheckboxChange(type)(value)}
  //         sx={{
  //           color: theme.palette.text.secondary,
  //           "&.Mui-checked": {
  //             color: theme.palette.text.active,
  //           },
  //         }}
  //       />
  //     }
  //     label={labelKey}
  //   />
  // );

  // const renderMealCheckboxes = () => (
  //   <Box
  //     sx={{
  //       display: "flex",
  //       flexDirection: { xs: "column", md: "row" },
  //       gap: { md: 4 },
  //     }}
  //   >
  //     <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
  //       {meal?.length &&
  //         meal?.map((x, i) =>
  //           renderCheckbox(
  //             i18n.language === "de" ? x?.german : x?.meal_name,
  //             x?.id,
  //             "meal",
  //             i
  //           )
  //         )}
  //     </Box>
  //   </Box>
  // );

  // const renderDietCheckboxes = () => (
  //   <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
  //     {diet?.length &&
  //       diet?.map((x, i) =>
  //         renderCheckbox(
  //           i18n.language === "de" ? x?.german : x?.diet_name,
  //           x?.id,
  //           "diet",
  //           i
  //         )
  //       )}
  //   </Box>
  // );

  // const handlemeal = async () => {
  //   try
  //   {
  //     let res = await API_GET(API_endpoints.getmealcat);
  //     if (res?.s) setMeal(res?.r);
  //   } catch (error)
  //   {
  //     console.log(error);
  //   }
  // };

  // const handlediet = async () => {
  //   try
  //   {
  //     let res = await API_GET(API_endpoints.getdietcat);
  //     if (res?.s)
  //     {
  //       setDiet(res?.r);
  //     }
  //   } catch (error)
  //   {
  //     console.log(error);
  //   }
  // };

  const GetEquipmentRequirement = async () => {
    try {
      let res = await API_GET(API_endpoints.getTruckEquipmentRequirerment);
      if (res?.s) {
        setEquipment(res?.r);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // handlemeal();
    // handlediet();
    GetEquipmentRequirement();
  }, [i18n.language]);

  useEffect(() => {
    getTruck(data?.id);
  }, [data]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="hideScrollbar"
          sx={{
            ...style,
            width: { xs: "90%", md: "calc(100% - 50px)" },
            overflowX: "hidden",
          }}
        >
          <Box>
            <Box className="flexBetween">
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: { xs: "18px", md: "22px", lg: "26px" },
                  color: theme.palette.text.active,
                }}
              >
                {t("becomePartner.editModal.title")}
              </Typography>
              <Box></Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.image.title")}
                </Box>
                <DragandDrop
                  setImage={setImage}
                  error={!image}
                  helperText={!image && "Required"}
                  apiPreview={
                    typeof formik.values.image === "string"
                      ? API_endpoints.baseURL + formik.values.image
                      : null
                  }
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: { xs: 2, sm: 2 },
                  flexDirection: "column",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.foodTruckDetails.truckName.title")}
                  </Box>

                  <TextField
                    type="text"
                    id="truck_name"
                    name="truck_name"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.truck_name}
                    error={
                      formik.touched.truck_name &&
                      Boolean(formik.errors.truck_name)
                    }
                    helperText={
                      formik.touched.truck_name && formik.errors.truck_name
                    }
                    variant="outlined"
                    placeholder={t(
                      "becomePartner.step2.foodTruckDetails.truckName.pl"
                    )}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.foodTruckDetails.address.title")}
                  </Box>

                  <TextField
                    type="text"
                    id="address"
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                    variant="outlined"
                    placeholder={t(
                      "becomePartner.step2.foodTruckDetails.address.pl"
                    )}
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.zipCode.title")}
                </Box>
                <TextField
                  type="number"
                  id="zip_code"
                  name="zip_code"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.zip_code}
                  error={
                    formik.touched.zip_code && Boolean(formik.errors.zip_code)
                  }
                  helperText={formik.touched.zip_code && formik.errors.zip_code}
                  variant="outlined"
                  placeholder={t(
                    "becomePartner.step2.foodTruckDetails.zipCode.pl"
                  )}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.area.title")}
                </Box>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.work_area_radius &&
                    Boolean(formik.errors.work_area_radius)
                  }
                  sx={{
                    color: theme.palette.text.defaultDark,
                    textAlign: "start",
                    borderRadius: "12px",
                  }}
                >
                  <Select
                    fullWidth
                    id="work_area_radius"
                    name="work_area_radius"
                    value={+formik.values.work_area_radius}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      color: theme.palette.text.select_input,
                      borderRadius: "12px",
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                      },
                      "&:active": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                      },
                    }}
                  >
                    <MenuItem
                      style={{
                        color: theme.palette.text.defaultDark,
                        display: "none",
                      }}
                      value={0}
                    >
                      {t("becomePartner.step2.foodTruckDetails.area.pl")}
                    </MenuItem>

                    {[
                      {
                        label: "50Km",
                        value: 50,
                      },
                      {
                        label: "100Km",
                        value: 100,
                      },
                      {
                        label: "250Km",
                        value: 250,
                      },
                      {
                        label: "500Km",
                        value: 500,
                      },
                      {
                        label: t("becomePartner.step2.foodTruckDetails.wide"),
                        value: -1,
                      },
                    ]?.map((e, i) => (
                      <MenuItem
                        style={{ color: theme.palette.text.defaultDark }}
                        key={i}
                        value={e.value}
                      >
                        {e.label}
                      </MenuItem>
                    ))}
                  </Select>

                  {formik.touched.work_area_radius &&
                    formik.errors.work_area_radius && (
                      <FormHelperText>
                        {formik.errors.work_area_radius}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
            </Box>

            {/* <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.category.title")}
                </Box>
                <FormControl
                  fullWidth
                  error={formik.touched.meal && Boolean(formik.errors.meal)}
                  sx={{
                    color: theme.palette.text.defaultDark,
                    textAlign: "start",
                    borderRadius: "12px",
                  }}
                >
                  <Autocomplete
                    multiple
                    sx={{
                      borderRadius: "12px",
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                      },
                      "&:active": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                      },
                    }}
                    id="meals"
                    name="meal"
                    options={meal || []}
                    getOptionLabel={(option) =>
                      i18n.language === "en-US"
                        ? option.meal_name
                        : option.german
                    }
                    value={
                      meal.filter((m) => formik.values.meal?.includes(m?.id)) ||
                      []
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "meal",
                        newValue.map((m) => m.id)
                      );
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          !params?.InputProps?.startAdornment?.length
                            ? t("search.input1")
                            : null
                        }
                        error={
                          formik.touched.meal && Boolean(formik.errors.meal)
                        }
                        helperText={formik.touched.meal && formik.errors.meal}
                        sx={{
                          "& .MuiInputBase-input": {
                            color: theme.palette.text.defaultDark,
                          },
                          "& .MuiInputLabel-root": {
                            color: theme.palette.text.strip,
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: theme.palette.background.strip,
                          },
                          "& .MuiFormHelperText-root": {
                            color: theme.palette.text.error,
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "12px",
                              borderColor: `1px solid ${theme.palette.background.strip}`,
                            },
                          },
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => (
                        <Chip
                          key={option.id}
                          label={
                            i18n.language === "en-US"
                              ? option.meal_name
                              : option.german
                          }
                          {...getTagProps({ index })}
                          sx={{
                            color: theme.palette.text.defaultDark,
                            "& .MuiChip-deleteIcon": {
                              color: "black",
                            },
                          }}
                        />
                      ))
                    }
                    PaperComponent={({ children }) => (
                      <Paper
                        sx={{
                          bgcolor: theme.palette.background.main,
                          color: theme.palette.text.strip,
                        }}
                      >
                        {children}
                      </Paper>
                    )}
                    ListboxProps={{
                      sx: {
                        bgcolor: theme.palette.background.main,
                        color: theme.palette.text.defaultDark,
                        "& .MuiAutocomplete-option": {
                          "&:hover": {
                            bgcolor: theme.palette.background.inputBgHover,
                            color: theme.palette.text.active,
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG /> {t("editTruck.diet")}
                </Box>
                <FormGroup row>{renderDietCheckboxes()}</FormGroup>
                {formik.touched.diet && formik.errors.diet && (
                  <FormHelperText error>{formik.errors.diet}</FormHelperText>
                )}
              </Box>
            </Box> */}
            {/* 
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.desc.title")}
              </Box>

              <TextField
                type="text"
                id="description"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                variant="outlined"
                placeholder={t("becomePartner.step2.foodTruckDetails.desc.pl")}
                multiline
                fullWidth
                rows={4}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box> */}

            {/* <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 1,
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG /> {t("bookNowForm.catering_Type")}
                </Box>
                <FormGroup row>{renderMealCheckboxes()}</FormGroup>
                {formik.touched.meal && formik.errors.meal && (
                  <FormHelperText error>{formik.errors.meal}</FormHelperText>
                )}
              </Box>


          </Box> */}
            <Box>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "24px",
                  color: theme.palette.text.active,
                  mt: 2,
                }}
              >
                {t("becomePartner.step2.questions.title")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG /> {t("editTruck.size")}
                </Box>
                <TextField
                  type="number"
                  id="size"
                  name="size"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.size}
                  error={formik.touched.size && Boolean(formik.errors.size)}
                  helperText={formik.touched.size && formik.errors.size}
                  variant="outlined"
                  placeholder={t("becomePartner.step2.questions.truckSize.pl")}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.questions.electricity.title")}
                </Box>

                <FormControl
                  fullWidth
                  error={
                    formik.touched.requirement_ids &&
                    Boolean(formik.errors.requirement_ids)
                  }
                  sx={{
                    color: theme.palette.text.defaultDark,
                    textAlign: "start",
                    borderRadius: "12px",
                  }}
                >
                  <Autocomplete
                    multiple
                    sx={{
                      borderRadius: "12px",
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                      },
                      "&:active": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                      },
                    }}
                    id="requirement_idss"
                    name="requirement_ids"
                    options={equipment || []}
                    getOptionLabel={(option) =>
                      i18n.language === "de"
                        ? option.german
                        : option.requirement
                    }
                    value={
                      equipment?.filter((m) =>
                        formik.values.requirement_ids?.includes(m.id)
                      ) || []
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "requirement_ids",
                        newValue.map((m) => m.id)
                      );
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.requirement_ids &&
                          Boolean(formik.errors.requirement_ids)
                        }
                        label={
                          !params?.InputProps?.startAdornment?.length
                            ? t("becomePartner.step2.questions.electricity.pl")
                            : null
                        }
                        helperText={
                          formik.touched.requirement_ids &&
                          formik.errors.requirement_ids
                        }
                        sx={{
                          "& .MuiInputBase-input": {
                            color: theme.palette.text.defaultDark,
                          },
                          "& .MuiInputLabel-root": {
                            color: theme.palette.text.strip,
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: theme.palette.background.strip,
                          },
                          "& .MuiFormHelperText-root": {
                            color: theme.palette.text.error,
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "12px",
                              borderColor: `1px solid ${theme.palette.background.strip}`,
                            },
                          },
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.length > 0
                        ? value?.map((option, index) => (
                            <Chip
                              key={option.id}
                              label={
                                i18n?.language === "de"
                                  ? option?.german
                                  : option?.requirement
                              }
                              {...getTagProps({ index })}
                              sx={{
                                color: theme.palette.text.defaultDark,
                                "& .MuiChip-deleteIcon": {
                                  color: "black",
                                },
                              }}
                            />
                          ))
                        : t("becomePartner.step2.questions.electricity.pl")
                    }
                    PaperComponent={({ children }) => (
                      <Paper
                        sx={{
                          bgcolor: theme.palette.background.main,
                          color: theme.palette.text.strip,
                        }}
                      >
                        {children}
                      </Paper>
                    )}
                    ListboxProps={{
                      sx: {
                        bgcolor: theme.palette.background.main,
                        color: theme.palette.text.defaultDark,
                        "& .MuiAutocomplete-option": {
                          "&:hover": {
                            bgcolor: theme.palette.background.inputBgHover,
                            color: theme.palette.text.active,
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.questions.operatingMode.title")}{" "}
                </Box>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  row
                  value={formik.values.operating_mode}
                  onChange={(e) => {
                    formik.setFieldValue("operating_mode", e.target.value);
                  }}
                  name="operating_mode"
                >
                  <FormControlLabel
                    value="KWH"
                    control={<Radio />}
                    label={`${t(
                      "becomePartner.step2.questions.operatingMode.op1"
                    )}`}
                  />
                  <FormControlLabel
                    value="GAS"
                    control={<Radio />}
                    label={`${t(
                      "becomePartner.step2.questions.operatingMode.op2"
                    )}`}
                  />
                </RadioGroup>
                {formik.touched.operating_mode &&
                  formik.errors.operating_mode && (
                    <Typography color="error">
                      {formik.errors.operating_mode}
                    </Typography>
                  )}
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG /> {t("becomePartner.step2.questions.water.title")}
                </Box>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="female"
                  row
                  value={formik.values.is_water_required}
                  onChange={formik.handleChange}
                  name="is_water_required"
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={`${t("becomePartner.step2.questions.water.op1")}`}
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={`${t("becomePartner.step2.questions.water.op2")}`}
                  />
                </RadioGroup>
                {formik.touched.is_water_required &&
                  formik.errors.is_water_required && (
                    <Typography color="error">
                      {formik.errors.is_water_required}
                    </Typography>
                  )}
              </Box>
            </Box>
          </Box>
          <Box className="flexCenter" sx={{ my: 2 }}>
            <ButtonComponent
              type="button"
              label={t("becomePartner.editModal.save")}
              // label={`${t("Done")}`}
              onClick={formik.handleSubmit}
              sx={{
                width: "120px",
                background: theme.palette.background.btn,
                color: theme.palette.text.defaultLight,
                fontSize: "16px",
                overflow: "hidden",
              }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
